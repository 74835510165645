import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Streetview from "../images/Streetview.jpg"
import Concert from "../images/Victory concert-page-001.jpg"
import revival from "../images/revival.jpg"
import revival2 from "../images/revival2.jpg"
import revival3 from "../images/revival3.jpg"
import revival4 from "../images/revival4.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="wrapper">
      <div className="title">
        <h2 id="land1">
          Victory Chapel
          <br />
          of Eloy
        </h2>
        <h1 id="land">
          Changing lives,
          <br />
          Making disciples,
          <br />
          Reaching the world
        </h1>
      </div>
      <hr />

      <div className="content">
        <img src={Streetview} alt="Eloy Victory Chapel Streetview" />
        <img src={Concert} alt="Concert" />
        <img src={revival} alt="revival" />
        <img src={revival2} alt="revival2" />
        <img src={revival3} alt="revival3" />
        <img src={revival4} alt="revival4" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
